import * as React from "react"
import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout pageTitle="G13 Railway Operations Model">
      <p>The fundamental role of the ROM is to describe a railway transport business scenario in arithmetic terms linked from market through engineering and operations to the financial outcome by:</p>
      <ul>
        <li> Postulating  physical and price inputs;</li>
        <li> Mathematically describing business activity and calculating physical outcomes;</li>
        <li> Applying  $ costs and $ revenues to inputs and outcomes; and</li>
        <li> Expressing results in both physical and financial terms.</li>
      </ul>
      <p> As delivered, the ROM is populated with default data available from the public record.  This includes:</p>
      <ul>
        <li> Major Australian public and private railway networks including terminals;</li>
        <li> Most train services currently known or postulated;</li>
        <li> Databases of locomotives and wagons; and</li>
        <li> Published prices and costs.</li>
      </ul>
      <p>This data allows a user to commission a comprehensive railway business scenario very rapidly. It is then possible to use the ROM analytical capability to determine what data will most influence business decisions...The material issues can then be the preoccupation and immaterial data can be left in near default form.</p>
    </Layout>
  )
}

export default IndexPage
